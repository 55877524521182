<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" width="40" height="32" />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Inscription" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          style="padding-top: 75px"
        >
          <b-card-text class="mb-2">
            <p>Inscrivez-vous et commencer 🚀</p>
            <p>Créer votre plateforme de gestion !</p>
          </b-card-text>

          <!-- <div class="alert alert-danger mt-4" v-if="errors.length">
              <ul class="mb-0">
                <li v-for="(errors, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
          </div> -->

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- corporation -->
              <b-form-group
                label="Code de l'Organisation"
                label-for="sku_corporation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sku_corporation"
                  vid="sku_corporation"
                  rules="required"
                >
                  <b-form-input
                    id="sku_corporation"
                    v-model="username"
                    name="sku_corporation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex: DXB-EX001, Huawei, Inc"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                label="Compte d'utilisateur"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ex: P E Lumumba"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="test@orosep.org"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Mot de passe">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="register-privacy-policy"
                  vid="status"
                  rules="required"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="register-privacy-policy"
                    :state="errors.length > 0 ? false : null"
                  >
                    J'accepte
                    <b-link>le contrat de licence</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                S'inscrire
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Déjà inscrit?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Se connecter</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">ou</div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";

import { $themeConfig } from "@themeConfig";

export default {
  //name: 'AddContact',
  setup() {
    // App Name
    const { appName, appLogoImage, api_endpoint } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
      api_endpoint,
    };
  },
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    //
    console.log($themeConfig.app.api_endpoint);
  },
  data() {
    return {
      //contacts: {},

      status: "",
      username: "",
      userEmail: "",
      password: "",
      status: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      errors: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    saveContact() {
      // this.errors=[];
      // if(!this.contact.name){
      //   this.errors.push("Name is required");
      // }
      // if(!this.contact.userEmail){
      //   this.errors.push("Email is required");
      // }
      // if(!this.contact.designation){
      //   this.errors.push("Designation is required");
      // }
      // if(!this.contact.name){
      //   this.errors.push("Contact N° is required");
      // }
    },
    async register() {
      // for the web app
      // if(!this.errors.length){

      //     let formData = new FormData();
      //     formData.append('name', this.username);
      //     formData.append('email', this.userEmail);
      //     formData.append('designation', this.password);
      //     formData.append('contact_no', this.status);

      //     // alert($themeConfig.app.api_endpoint);

      //     //
      //     let url = $themeConfig.app.api_endpoint + 'save_contact';
      //     await axios.post(url, formData).then((response)=>{
      //       console.log(response);
      //       if(response.status==200){

      //         //alert(response.data.message);

      //         // useJwt.setToken(response.data.accessToken)
      //         // useJwt.setRefreshToken(response.data.refreshToken)
      //         // localStorage.setItem('userData', JSON.stringify(response.data.userData))
      //         // this.$ability.update(response.data.userData.ability)
      //         this.$router.push('/')

      //       }else{
      //         this.errors.push(response.message);
      //       }
      //     }).catch(error=>{
      //       
      //     })

      //     console.log('error');
      // }else{
      //    alert("Error, please fill all data");
      // }
      // return;

      // for local pwa, other
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          useJwt
            .register({
              username: this.username,
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.userData)
              );
              this.$ability.update(response.data.userData.ability);
              this.$router.push("/");
            })
            .catch((error) => {
              this.$refs.registerForm.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
